import {
    Fragment,
    lazy,
    Suspense,
    useRef,
    memo,
    useEffect,
    useCallback,
} from "react";
import { injectIntl } from "react-intl";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import useVisible from "@tteenbe/hookUseVisible";
import { useDispatch } from "react-redux";
import * as Actions from "./store/actions/promocion-actions";
import { ImageAsyn } from "components/ImageAsyc";
import slider1 from "assets/image/slider/slider1.jpg";
import slider2 from "assets/image/slider/slider2.jpg";
import slider3 from "assets/image/slider/slider3.jpg";

const AcercaSection = lazy(() =>
    import(
        /* webpackChunkName: "views-home-acerca-section" */ "./components/Acerca-Section"
    )
);
const AboutImageSection = lazy(() =>
    import(
        /* webpackChunkName: "views-home-about-image-section" */ "./components/About-Image-Section"
    )
);
const TextLeftSection = lazy(() =>
    import(
        /* webpackChunkName: "views-home-text-section" */ "./components/Text-Left-Section"
    )
);
// const TextRightSection = lazy(() =>
//     import(
//         /* webpackChunkName: "views-home-text-section" */ "./components/Text-Right-Section"
//     )
// );
const ServiciosSection = lazy(() =>
    import(
        /* webpackChunkName: "views-home-servicios-section" */ "./components/Servicios-Section"
    )
);
const ContactoSection = lazy(() =>
    import(
        /* webpackChunkName: "views-home-contacto-section" */ "./components/Contacto-Section"
    )
);

const items = [
    <Fragment>
        <Suspense
            fallback={
                <div style={{ height: "100vh" }}>
                    <div className="loading" />
                </div>
            }
        >
            <ImageAsyn src={slider1} alt="otros" className="sliderImage" />
            <div className="texto-encima w-100 tp-caption text1 align_center">
                {/* <h5> - Reconocimiento a merito individual y trayectoria profesional -</h5>
                <h2>Samy Spa</h2> */}
                {/* <a href="appointment_page.html" className="mad_button">
                    Register Now
                </a> */}
            </div>
        </Suspense>
    </Fragment>,
    <Fragment>
        <Suspense
            fallback={
                <div style={{ height: "100vh" }}>
                    <div className="loading" />
                </div>
            }
        >
            <ImageAsyn src={slider2} alt="otros" className="sliderImage" />
            <div
                className="texto-encima w-100 tp-caption text1 align_center"
                data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;"
                data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
                data-start="500"
                data-x="center"
                data-y="top"
            >
                {/* <h5> - Tu confortabilidad es nuestra mision -</h5>
                <h2>Nuestros Servicios</h2> */}
                {/* <a href="appointment_page.html" className="mad_button">
                    ...
                </a> */}
            </div>
        </Suspense>
    </Fragment>,
    <Fragment>
        <Suspense
            fallback={
                <div style={{ height: "100vh" }}>
                    <div className="loading" />
                </div>
            }
        >
            <ImageAsyn src={slider3} alt="otros" className="sliderImage" />
            <div
                className="texto-encima w-100 tp-caption text1 align_center"
                data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;"
                data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
                data-start="500"
                data-x="center"
                data-y="top"
            >
                {/* <h5> - -</h5>
                <h2>Masajes especiales</h2>
                <a href="appointment_page.html" className="mad_button">
                    ...
                </a> */}
            </div>
        </Suspense>
    </Fragment>,
];

const Home = () => {
    const dispatch = useDispatch();

    const elemRefAcercaSection = useRef();
    const isVisibleAcercaSection = useVisible(elemRefAcercaSection);
    const elemRefAboutImageSection = useRef();
    const isVisibleAboutImageSection = useVisible(elemRefAboutImageSection);
    const elemRefTextLeftSection = useRef();
    const isVisibleTextLeftSection = useVisible(elemRefTextLeftSection);
    // const elemRefTextRightSection = useRef();
    // const isVisibleTextRightSection = useVisible(elemRefTextRightSection);
    const elemRefServiciosSection = useRef();
    const isVisibleServiciosSection = useVisible(elemRefServiciosSection);
    const elemRefContactoSection = useRef();
    const isVisibleContactoSection = useVisible(elemRefContactoSection);

    const fetchBusinesses = useCallback(() => {
        dispatch(Actions.getHomePromocion());
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [dispatch]);
    useEffect(() => {
        fetchBusinesses();
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [fetchBusinesses]);

    return (
        <Fragment>
            <div className="tp-banner-container">
                <div className="rev_slider_wrapper">
                    <div id="slider1" className="rev_slider">
                        <AliceCarousel
                            mouseTracking
                            autoPlay={true}
                            // autoPlayControls
                            autoPlayStrategy="none"
                            autoPlayInterval={5000}
                            animationDuration={1000}
                            animationType="fadeout"
                            infinite
                            touchTracking={false}
                            disableDotsControls
                            disableButtonsControls
                            items={items}
                        />

                        <svg
                            className="separator_type_5_path"
                            preserveAspectRatio="none"
                            viewBox="0 0 100 100"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M0 0 L70 100 L100.1 0 L100.1 100 L0 100 Z"
                                fill="#fff"
                                stroke="#fff"
                                strokeWidth="-1"
                            ></path>
                            <path
                                d="M0 0 L0 0 L50 100 L100 0 L100 0 Z"
                                fill="transparent"
                            ></path>
                        </svg>
                    </div>
                </div>
            </div>

            <div id="content">
                <div id="about">
                    <div
                        className="mad_section inset_none"
                        ref={elemRefAcercaSection}
                    >
                        {isVisibleAcercaSection && (
                            <Suspense
                                fallback={
                                    <div>
                                        <div className="loading" />
                                    </div>
                                }
                            >
                                <AcercaSection />
                            </Suspense>
                        )}
                    </div>
                </div>
            </div>

            <div id="content">
                <div id="about">
                    <div
                        className="mad_section inset_none v_align_center_blocks"
                        ref={elemRefAboutImageSection}
                    >
                        {isVisibleAboutImageSection && (
                            <Suspense
                                fallback={
                                    <div>
                                        <div className="loading" />
                                    </div>
                                }
                            >
                                <AboutImageSection />
                            </Suspense>
                        )}
                    </div>
                </div>
            </div>

            <div className="mad_section" ref={elemRefTextLeftSection}>
                {isVisibleTextLeftSection && (
                    <Suspense
                        fallback={
                            <div>
                                <div className="loading" />
                            </div>
                        }
                    >
                        <TextLeftSection />
                    </Suspense>
                )}
            </div>

            <div id="services">
                <div
                    className="paralax_image_bg2"
                    ref={elemRefServiciosSection}
                >
                    {isVisibleServiciosSection && (
                        <Suspense
                            fallback={
                                <div>
                                    <div className="loading" />
                                </div>
                            }
                        >
                            <ServiciosSection />
                        </Suspense>
                    )}
                </div>

                {/* <div
                    id="services3"
                    className="mad_section inset_none v_align_center_blocks my-4"
                    ref={elemRefTextRightSection}
                >
                    {isVisibleTextRightSection && (
                        <Suspense
                            fallback={
                                <div>
                                    <div className="loading" />
                                </div>
                            }
                        >
                            <TextRightSection />
                        </Suspense>
                    )}
                </div> */}
                <br />
                <br />
            </div>

            <div id="contact">
                <div ref={elemRefContactoSection}>
                    {isVisibleContactoSection && (
                        <Suspense
                            fallback={
                                <div>
                                    <div className="loading" />
                                </div>
                            }
                        >
                            <ContactoSection />
                        </Suspense>
                    )}
                </div>
            </div>

            <br />
            <br />
        </Fragment>
    );
};

export default injectIntl(memo(Home));
